<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="medium" color="#fff" type="ball-spin-clockwise-fade-rotating"
			 style="position:false !important">
</ngx-spinner>
<p-button   label="Download Audit Trail" (click)="exportDealerVolumeCommitmentInExcel()" id="file_download_button" class = "button_style" type="submit"
			tooltipPosition="top" ></p-button>


  <div class="noRecordFound" *ngIf="isNoRecordFound">
	<p>
	  <strong>{{ systemMonth }} Wholesale is yet to commence</strong>
	</p>
  </div>
  <div *ngIf="!isAuthorizedUser">
	  <div class="errorAuthId">
		<p>
		  <strong>You are not an authorized user.</strong>
		</p>
	  </div>
  </div>