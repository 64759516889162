<div *ngIf="show">
	<form (ngForm)="formHome">
		<h2 *ngIf="!marketCodeShow" id="homePageText" translate>HomePage</h2>
		<div *ngIf="dashBoardShow" class="ui-inputgroup">
			&nbsp;&nbsp;&nbsp;<a href="https://www.qlik.ford.com/sense/app/38210535-9252-46de-9b4d-3ed340693fcd/sheet/84fe2229-2d29-4a66-8bb8-a29cb2e7be27/state/analysis"
								 target="_blank">
			<strong>
				<ul>Link to Wholesale Dashboard</ul>
			</strong></a>
		</div>
		<div *ngIf="marketCodeShow" class="ui-inputgroup">
			<h5 class="first">Select Market &nbsp;
				<p-dropdown (onChange)="onMarketCodeSelect($event.value)" [options]="marketList"
							id="select-market-code" name="marketCode"
							placeholder='--Select--'></p-dropdown>
			</h5>
		</div>
	</form>
</div>

<br>
<br>

<div *ngIf="messageShow">
	<h1 style="text-align: center;color: midnightblue;">Wholesale Letter</h1>
	<br>
	<div style="border-style:dotted;
   border-color:darkblue;padding-right: 20px;">
		<br>
		<div style="padding-left: 20px;">
			<div [innerHTML]="messageBody" style="padding-top: 10px;"></div>
			<br>
		</div>
	</div>
</div>

<div *ngIf="unauthShow">
	<form (ngForm)="unauthorizationForm">
		<div class="errorAuthId">
			<p>
				<strong translate>You are not an authorized user.</strong>
			</p>
		</div>
	</form>
</div>
