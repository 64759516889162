<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="medium" color="#fff" type="ball-spin-clockwise-fade-rotating"
			 style="position:false !important">
</ngx-spinner>
<div>
	<div class="p-fieldset">
		<div id="tab">
			<div class="grid ui-fluid">
				<div class="col-6">
					<div class="grid p-dir-row">
						<div class="col-3" *ngIf="showDealerDetailDiv">
							<h5 translate>Dealer</h5>
						</div>
						<div class="col-9" id="dealerDiv" *ngIf="showDealerDetailDiv">
							<h5>: &nbsp; &nbsp; {{dealerId}} {{dealerName}} </h5>
						</div>
						<br/>
						<div class="col-3">
							<h5 translate>Wholesale Month</h5>
						</div>
						<div class="col-9" id="wholesaleMonthDiv">
							<h5>: &nbsp; &nbsp;
								{{(wholesaleCurrentMonth.substring(0, wholesaleCurrentMonth.indexOf("-"))
									|translate) + wholesaleCurrentMonth.substring(wholesaleCurrentMonth.indexOf("-"), wholesaleCurrentMonth.length)}}
							</h5>
						</div>
					</div>
				</div>
				<div class="col-6">
					<button pButton type="button" (click)="submitAllocationVolume()"
							label="Confirm Entity Mix"></button>
				</div>
			</div>
		</div>
	</div>
	<br/>

	<div class="grid">
		<div class="col-1">
			<h5><strong>Vehicle Line</strong></h5>
		</div>
		<div class="col-2 nowrap">
			<p-dropdown id="vehicleLinesList" [options]="vehicleLinesList" #selectVehicle
						[(ngModel)]="selectedVehicle"
						name="selectedVehicleLine" optionLabel="marketingNameplate"
						(onChange)="getSpecificationsByVehicleLine()"
						placeholder='--Select--'></p-dropdown>
		</div>
	</div>
	<br/>
	<div *ngIf="!isServerError && specificationsMapped?.length > 0">
		<p-table #dt [value]="specificationsMapped" [paginator]="true" [rows]="pageSize" [(first)]="first">
			<ng-template pTemplate="header" let-rowData>
				<tr>
					<th scope="col"> Ordering Code</th>
					<th scope="col"> Specification/Series</th>
					<th scope="col"> Volumes</th>

				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-rowData>
				<tr [pSelectableRow]="rowData" class="lbltbl">

					<td>
						{{rowData.code}}
					</td>
					<td>
						{{rowData['description']}}
					</td>
					<td>
						<input type="number" [(ngModel)]="rowData.volume"
							   (ngModelChange)="refreshTotalEntityMixCalculated()"/>
					</td>

				</tr>
			</ng-template>
			<ng-template pTemplate="footer" let-columns>
				<tr>
					<td colspan="5" style="text-align: right;">
						{{totalEntityMix}} / {{commitmentCount}}
					</td>
				</tr>
			</ng-template>
		</p-table>
		<br/>
	</div>
	<div class="errorAuthId" *ngIf="isServerError">
		<p>
			<strong>{{errorMsg}}</strong>
		</p>
	</div>
</div>
