<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" color="#fff" size="medium" style="position:false !important"
			 type="ball-spin-clockwise-fade-rotating">
</ngx-spinner>
<div *ngIf="isZoneManager">
	<div *ngIf="isAuthorizedUser">
		<form (ngForm)="formWholesale" (ngSubmit)="onCommit()">

			<div id="tab">
				<div class="grid ui-fluid">
					<div class="col-5 p-sm-12">
						<div class="grid p-dir-row ui-fluid">
							<div class="col-2 p-sm-12">
								<h5 class="h5-pd">District &nbsp; </h5>
							</div>
							<div class="col-3 p-sm-12">
								<p-dropdown (onChange)="onDistrictSelect($event.value)"
											[(ngModel)]="selectedDistrict"
											[options]="districtDropdownList" id="select-district"
											name="district"
											optionLabel="districtCode"
											placeholder='--Select--'></p-dropdown>

							</div>


							<div class="col-2 p-sm-12">
									<h5 class="h5-pd">Zone &nbsp; </h5>
							</div>
							<div class="col-3 p-sm-12">
									<p-dropdown (onChange)="onZoneSelect()" [(ngModel)]="selectedZone"
												[options]="zoneDropdownList" id="select-zone"
												name="zone"
												optionLabel="zoneCode"
												placeholder='--Select--'></p-dropdown>


							</div>
						</div>

					</div>
					<div class="col-1">
						<span><strong>-OR-</strong></span>
					</div>
					<div *ngIf="isFobMarket" class="col-3">

						<div class="grid">
							<div class="col-3">
								<h5 class="h5-pd">Dealer Group</h5>
							</div>
							<div class="col-5">
								<p-dropdown (onChange)="onDealerGroupSelect()" [(ngModel)]="selectedDealerGroup"
											[filter]="true" [options]="dealerGroupList"
											id="select-dealer-group"
											name="dealerGroup" placeholder='--Select--'></p-dropdown>

							</div>
						</div>

					</div>
					<div *ngIf="isFxMarket" class="col-3 p-sm-12">

						<div class="grid p-dir-row ui-fluid">
							<div class="col-3">
								<h5 class="h5-pd">Dealer &nbsp;</h5>
							</div>
							<div class="col-2">
								<p-dropdown (onChange)="onDealerIdSelect()" [(ngModel)]="selectedDealerId"
											[filter]="true" [options]="dealerIdList"
											id="select-dealer-group" name="dealerGroup"
											placeholder='--Select--'></p-dropdown>

							</div>
						</div>

					</div>

					<div class="col-1">
						<h5 class="h5-pd">Wholesale Month &nbsp;</h5>
					</div>
					<div class="col-2">
							<input [disabled]="true" id="market" name="market"
								   pInputText type="text"
								   value={{wholesaleCurrentMonth}}/>
					</div>

				</div>
				<div class="form-group content-section implementation commitButtonPadding">
					<p-button *ngIf="showCommitButton" [disabled]="commitButtonDisable" [pTooltip]="commitStatus"
							  [ngStyle]="commitButtonDisable ? {'color': '#27405f'}: {'color': '#ffffff'}" id="commit_button" label={{buttonLabel}}
							  tooltipPosition="top" type="submit"></p-button>
				</div>
			</div>

		</form>
		<br>
	</div>
</div>
<div *ngIf="isNoRecordFound && isWholesaleStatusOpen" class="noRecordFound">
	<p>
		<strong>No Volumes allocated for any Vehicle Line</strong>
	</p>
</div>
<div *ngIf="isNoRecordFound && !isWholesaleStatusOpen" class="noRecordFound">
	<p>
		<strong>{{ systemMonth }} Wholesale is yet to commence</strong>
	</p>
</div>

<div *ngIf="isServerError" class="errorAuthId">
	<p>
		<strong>{{errorMsg}}</strong>
	</p>
</div>
<wholesale-dealer-view *ngIf="showVehiclesTable" [wholesaleCommitmentResponseList]="wholesaleCommitmentResponseList">
</wholesale-dealer-view>
<div>
	<wholesale-tree *ngIf="showWholesaleDealerDetailTable"
					[wholesaleCommitmentResponseList]="wholesaleCommitmentResponseList">
	</wholesale-tree>
</div>

<div *ngIf="!isAuthorizedUser">
	<form (ngForm)="unauthorizationForm">
		<div class="errorAuthId">
			<p>
				<strong>You are not an authorized user.</strong>
			</p>
		</div>
	</form>
</div>
