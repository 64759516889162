<div class="app-component">
<p-table [value]="wholesaleCommitmentResponseList" id="vehiclesTable">
  <ng-template style="background-color: #a8bbe4;" pTemplate="header" let-columns>
    <tr>
      <th scope="col" style="background: #27405f;
      color: white;" translate>Vehicle Line</th>
      <th scope="col" style="background: #27405f;
      color: white;" translate>Production Month</th>
      <th scope="col" style="background: #27405f;
      color: white;" *ngIf="isDealerGroupDetailDiv" translate>Dealer ID</th>
      <th scope="col" style="background: #27405f;
      color: white;" *ngIf="isDealerGroupDetailDiv" translate> Dealer Name</th>
      <th scope="col" style="background: #27405f;
      color: white;" translate>Allocation</th>
      <th scope="col" style="background: #27405f;
      color: white;">
        <p-checkbox name="selectAllChkBox" id="selectAllChkBox" [(ngModel)]="selectAllForCopy" binary="true"
          (onChange)="handleSelectAll()"></p-checkbox>
        <button class="ui-button ui-corner-all copy-button" (click)="copyOver()" id="copyButton" translate>Copy >></button>
      </th>
      <th scope="col" style="background: #27405f;
      color: white;" translate>Commitment</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">
    <tr class="ui-widget-header" *ngIf="isVehicleLineTypeFirstIndexMatch(rowData, rowIndex)">
      <td [attr.colspan]= "canViewDealerGroup ? '8' : '7'" id="vehicleTypeHeader"
          style="font-size:15px;font-weight: bold;background-color: #1854900f;text-align: center;color:#2c1b5d" translate>
          {{vehicleType}} </td>
   </tr>
    <tr *ngIf="isRowVehicleLineFirstIndexMatch(rowData, rowIndex)">
      <td>
        <div class="left-align">{{rowData.vehicleLineName}}</div>
      </td>

      <td>
        <div class="center-align" id="productionMonthAndYear"> {{(rowData.productionMonthAndYear.substring(0, rowData.productionMonthAndYear.indexOf( "-" ))
          |translate)+rowData.productionMonthAndYear.substring(rowData.productionMonthAndYear.indexOf( "-" ),rowData.productionMonthAndYear.length)}}</div>
      </td>
      <td *ngIf="isDealerGroupDetailDiv">
        <div class="center-align">{{rowData.dealerId}}</div>
      </td>
      <td *ngIf="isDealerGroupDetailDiv">
        <div class="center-align">{{rowData.dealerName}}</div>
      </td>

      <td>
        <div class="right-align center-right-alignment">{{rowData.productionOffer}}</div>
      </td>

      <td>
        <div style="text-align: center">
          <p-checkbox name="selectChkBox" [(ngModel)]="rowData.selected" binary="true"
            (onChange)="checkIfAllSelected()"></p-checkbox>
        </div>
      </td>

      <td>

        <div class="center-align">
          <input pInputText #commitmentVal type="text" id="commitmentVal"
            style="width:75px;height:28px;text-align: right" value="{{rowData.allocationVehicleVolumeCommitCount}}"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            (input)="refreshDataGrid(commitmentVal.value,rowData)">
        </div>
      </td>

    </tr>
  </ng-template>
</p-table>
</div>
