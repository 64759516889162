<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="medium" color="#fff" type="ball-spin-clockwise-fade-rotating"
  style="position:false !important">
</ngx-spinner>
<div *ngIf="isDealer || isDealerGroup">
  <div *ngIf="isAuthorizedUser">
    <form (ngForm)="formWholesale" (ngSubmit)="onDealerCommit()">
		<div class="p-fieldset" >
        <div id="tab">
          <div class="grid ui-fluid">

            <div class="col-6">
              <div class="grid p-dir-row">
                <div class="col-3" *ngIf="showDealerDetailDiv">
                  <h5 *ngIf="!isDealerGroupDetailDiv" translate>Dealer</h5>
                  <h5 *ngIf="isDealerGroupDetailDiv" translate>Dealer Group</h5>
                </div>
                <div class="col-9" style="padding: 0%;padding-left: 10px;
                padding-top: 10px;" id="dealerDiv" *ngIf="showDealerDetailDiv">
                  <h5>: &nbsp; &nbsp; {{dealerId}} {{dealerName}} </h5>
                </div>



              <div class="col-3" >
                <h5 translate>Wholesale Month</h5>
              </div>
              <div class="col-9" id="wholesaleMonthDiv">
                <h5>: &nbsp; &nbsp; {{(wholesaleCurrentMonth.substring(0, wholesaleCurrentMonth.indexOf( "-" ))
                  |translate)+wholesaleCurrentMonth.substring(wholesaleCurrentMonth.indexOf( "-" ),wholesaleCurrentMonth.length)}}
                </h5>
              </div>
            </div>
            </div>

            <div class="col-6" >
              <p-button [label]="'Commit' | translate" id="commit_button" type="submit" tooltipPosition="top"

                [pTooltip]="commitStatus" [disabled]="commitButtonDisable" *ngIf="commitButtonShow">
              </p-button>
            </div>
          </div>
          <div id="confirmMessage" *ngIf="isConfirmMessage && commitButtonShow">
            <h5 style="text-align: right;"translate>Confirmation Message</h5>
            </div>

        </div>
		</div>
    </form>
    <br>
  </div>
  <div *ngIf="!wholesaleStatus && isStatus">
    <div class="alert  p-message p-message-info info-ford">
      <div>
        <strong>Please note that the wholesale tour is closed for your market</strong>
      </div>
     </div>
     </div>

  <div class="noRecordFound" *ngIf="isNoRecordFound && wholesaleStatus">
    <p>
      <strong translate>No Volumes allocated for any Vehicle Line</strong>
    </p>
  </div>

  <div class="noRecordFound" *ngIf="isNoRecordFound && !wholesaleStatus">
    <p>
      <strong>{{ systemMonth }} <span translate> Wholesale is yet to commence</span></strong>
    </p>
  </div>

  <div class="errorAuthId" *ngIf="isServerError">
    <p>
      <strong>{{errorMsg}}</strong>
    </p>
  </div>

  <wholesale-dealer-view  *ngIf="showVehiclesTable" [wholesaleCommitmentResponseList]="wholesaleCommitmentResponseList"></wholesale-dealer-view>
  <wholesale-tree *ngIf="showVehiclesTreeTable" [wholesaleCommitmentResponseList]="wholesaleCommitmentResponseList"></wholesale-tree>

</div>
