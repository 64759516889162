<div style="padding-left:20px;padding-right:20px;background: #27405f;">

<p-menubar  id="app-menubar" styleClass="ui-menubar-overide ui-menubar ipa ui-menuitem-link ui-menuitem-text"
		   [model]="navMenuItems"></p-menubar>
</div>
<p-sidebar id="app-sidebar" [(visible)]="sidebarVisible" position="right">
	<p-panelMenu [model]="navMenuItems" [multiple]="false"></p-panelMenu>
</p-sidebar>


