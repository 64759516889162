<div id="collapseId">
    <span style="cursor: pointer"
        (click)="checkIfVehicleLineSelectedUnderAtag();onExpandOrCollapseAllLink();"><strong translate>Expand/Collapse
            All</strong></span>
</div>
<div class="app-component">
    <p-table dataKey="vehicleLineName" [value]="wholesaleCommitmentResponseList" id="WholesaleDealersTable">
        <ng-template style="background-color: #a8bbe4;" pTemplate="header" let-columns>
            <tr>
                <th scope="col" style="width: 22%;background-color: #27405f;
                color: white;" *ngIf="ifVehicleLineExpanded">Vehicle Line</th>
                <th scope="col" style="background-color: #27405f;
                color: white;" *ngIf="!ifVehicleLineExpanded" translate>Production Month</th>
                <th scope="col" style="background-color: #27405f;
                color: white;" *ngIf = "canViewDealerGroup" translate>Dealer Group</th>
                <th scope="col" style="background-color: #27405f;
                color: white;" translate>Dealer Code</th>
                <th scope="col" style="background-color: #27405f;
                color: white;" translate>Dealer Name</th>
                <th scope="col" style="background-color: #27405f;
                color: white;" translate>Allocation</th>
                <th scope="col" style="background-color: #27405f;
                color: white;" *ngIf="!isDealerGroupHistory">
                    <p-checkbox name="selectAllChkBox" id="selectAllChkBox" [(ngModel)]="isCopyAllChecked" binary="true"
                        (onChange)="handleSelectAll()" [ngModelOptions]="{standalone: true}"></p-checkbox>
                    <button class="ui-button ui-corner-all copy-button" (click)="copyProdOfferToCommitment($event)"
                        id="copyButton" translate>Copy
                        >></button>
                </th>
                <th scope="col" style="background-color: #27405f;
                color: white;" translate>Commitment</th>
                <th scope="col" style="background-color: #27405f;
                color: white;" *ngIf="!isDealerGroupHistory" translate>Over / Under Allocation</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-expanded="expanded" let-columns="columns">
            <tr class="ui-widget-header" *ngIf="isVehicleLineTypeFirstIndexMatch(rowData, rowIndex)">
                <td [attr.colspan]= "canViewDealerGroup ? '8' : '7'" id="vehicleTypeHeader"
                    style="font-size:15px;font-weight: bold;background-color: #1854900f;text-align: center;color:#2c1b5d" translate>
                    {{vehicleType}} </td>
            </tr>
            <tr class="ui-widget-header" *ngIf="isRowVehicleLineFirstIndexMatch(rowData, rowIndex)">
                <td [attr.colspan]="canViewDealerGroup ? '4' : '3'" style="font-size: small;font-weight: bold; background-color: white;"
                    (click)="checkIfVehicleLineSelectedUnderTDtag()">
                    <a style="color:#27405f" class="Toggler" href="#" [pRowToggler]="rowData" (click)="checkIfVehicleLineSelectedUnderAtag()">
                        <em
                            [ngClass]="expanded ? 'fa fa-fw fa-chevron-circle-down' : 'fa fa-fw fa-chevron-circle-right'"></em>
                        <span style="color:#27405f" id="vehicleID" class="{{vehicleType}}">{{rowData.vehicleLineName}}</span>
                    </a>
                </td>
                <td style="background-color: white;">
                    <diV id="allocationTotal" class="vehicleLineTotal right-align center-right-alignment">
                        {{rowData.allocationTotal}}</diV>
                </td>
                <td *ngIf="!isDealerGroupHistory" style="background-color: white;">
                </td>
                <td style="background-color: white;">
                    <div id="commitmentTotal" class="vehicleLineTotal right-align center-right-alignment">
                        {{rowData.commitmentTotal}}</div>
                </td>
                <td *ngIf="!isDealerGroupHistory" style="background-color: white;">
                    <div id="overAndUnderTotal" class="vehicleLineTotal right-align center-right-alignment"
                        [style.color]="rowData.overAndUnderTotal < 0 ? 'red' : null">
                        {{rowData.overAndUnderTotal}}</div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-rowData let-rowIndex="rowIndex">
            <tr>
                <td *ngIf="getRowProductionMonthIndex(rowData) === rowIndex" style="background-color: white;"
                    [attr.rowspan]="getRowProductionMonthSpan(rowData)">
                    <div class="center-align" id="productionMonthAndYear">
                        {{rowData.productionMonthAndYear}}
                    </div>
                </td>
                <td *ngIf="canViewDealerGroup && getRowDealerGroupIndex(rowData) === rowIndex" style="background-color: white;"
                    [attr.rowspan]="getRowDealerGroupSpan(rowData)">
                    <div class="center-align">
                        {{rowData.dealerGroupName}}
                    </div>
                </td>
                <td style="background-color: white;">
                    <div id="dealerId" class="center-align">
                        {{rowData.dealerId}}
                    </div>
                </td>
                <td style="width:30%; background-color: white;">{{rowData.dealerName}}</td>
                <td style="background-color: white;">
                    <div id="productionOfferVal" class="right-align center-right-alignment">{{rowData.productionOffer}}
                    </div>
                </td>
                <td *ngIf="!isDealerGroupHistory" style="background-color: white;">
                    <div style="text-align: center;">
                        <p-checkbox name="selectChkBox" id="selectChkBox" (onChange)="checkIfAllSelected()"
                            [(ngModel)]="rowData.isCheckBoxSelected" binary="true"
                            [ngModelOptions]="{standalone: true}">
                        </p-checkbox>
                    </div>
                </td>
                <td *ngIf="!isDealerGroupHistory" style="width:10%;background-color: white;">
                    <div class="center-align">
                        <input pInputText #commitmentVal type="text" id="commitmentVal"
                            style="width:75px;height:28px;text-align: right"
                            value="{{rowData.allocationVehicleVolumeCommitCount}}"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                            (input)="refreshDataGrid(commitmentVal.value,rowData)">
                    </div>
                </td>
                <td *ngIf="isDealerGroupHistory" style="width:10%;background-color: white;">
                    <div class="center-align">{{rowData.allocationVehicleVolumeCommitCount}}</div>
                  </td>
                <td  *ngIf="!isDealerGroupHistory" style="background-color: white;">
                    <div id="overUnderVal" class="right-align center-right-alignment"
                        [style.color]="rowData.allocationVehicleVolumeCommitCount - rowData.productionOffer < 0 ? 'red' : null">
                        {{rowData.allocationVehicleVolumeCommitCount - rowData.productionOffer}}</div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
