export const environment = {

	production: true,
	local: false,

	baseCommitmentUrl_i: 'https://api.pd01e.gcp.ford.com/WholesaleCommitmentService',
	baseCommitmentUrl_e: 'https://api.pd01e.gcp.ford.com/WholesaleCommitmentService',
	baseProductionOfferUrl_i: 'https://production-offer-service-prod.apps.pd01i.edc1.cf.ford.com',
	baseProductionOfferUrl_e: 'https://production-offer-service-prod.apps.pd01e.edc1.cf.ford.com',

	baseWholesaleAllocationUrl_i: 'https://api.pd01e.gcp.ford.com/wholesaleallocationservice',
	baseWholesaleAllocationUrl_e: 'https://api.pd01e.gcp.ford.com/wholesaleallocationservice',
	baseDealerServiceUrl_i: 'https://api.pd01e.gcp.ford.com/dealerdetails',
	baseDealerService_e: 'https://api.pd01e.gcp.ford.com/dealerdetails',
	baseBulkOrderCreationUrl_i: 'https://api.pd01e.gcp.ford.com/bulkordercreation',
	baseBulkOrderCreationUrl_e: 'https://api.pd01e.gcp.ford.com/bulkordercreation',


	adfsBaseUrl: 'https://fd-usr-sso.login.sys.pd01.edc1.cf.ford.com',
	oauthUrl: 'https://fd-usr-grp-sso.login.sys.pd01.edc1.cf.ford.com/oauth/authorize?',
	client_id: '5210a487-8e83-4592-8c54-d39d5a497f23',

	redirectUrl_i: 'https://www.gws.ford.com',
	redirectUrl_e: 'https://www.gws.dealerconnection.com',

	saveVolumeCommitUrl: '/web/v1/volume-level-commitment',
	getAllVolumeCommitUrl: '/web/v1/vehicleline-volume-commitment',
	getWholesaleStatusUrl: '/web/v1/wholesaleStatus',
	getDealerDetailsUrl: '/web/v1/dealer-volume-commitment',
	getDealerGroupsUrl: '/web/v1/dealer-groups',
	getProductionOfferUrl: '/web/v1/get-production-offers',
	getDealerDetailUrl: '/web/v1/get-dealer-details',
	getDealerIdlistUrl: '/web/v1/dealer-ids',
	postEmailServiceUrl: '/web/v1/send-message',
	getDistrictAndZoneListUrl: '/web/v1/market-districts-zones',
	getMessageUrl: '/web/v1/message-broadcast',
	getVolumeCommitDataForAudit: '/web/v1/volume-commitment-by-market',

	openidUrl: 'https://corp.sts.ford.com/adfs/oauth2/authorize',
	openidClientId: 'urn:globalwholesalewebui:clientid:web_gwsdevwebsite:prod',
	openidResource: 'urn:globalwholesalewebui:resource:web_gwsdevwebsite:prod'
};
