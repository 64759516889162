<header id="app-header">
	<div id="app-branding" style="padding: 20px;">
		<div style="float: right">
			<div id="cdsUserId" style="text-align: right;width: 100%;line-height: 2"><span
				translate>You are logged in as</span>:
				{{loggedInUser}} </div>
			<div id="marketName" style="text-align: right; width: 100%"><span
				translate>Market</span>: {{loggedInUserMarketText}}</div>
			<div style="text-align: right; width: 100%"><a (click)="logoutEvent()" id="logoutId"
														   routerLink="/logout"><u><strong translate>Logout</strong></u></a>
			</div>
		</div>

		<div id="app-logo">
			<div id="app-icon">
				<img alt="Ford Oval" height="100px" src="assets/image/FordOval.png" width="267px"/>
			</div>
			<div id="app-header-text" routerLink="/home-page">
				<h1>Monthly Wholesale</h1>
			</div>
		</div>


	</div>
	<app-nav></app-nav>

</header>

<br/>

<main id="app-content" style="padding-left: 50px;padding-right: 50px;margin-top: 20px;">
	<div class="card" >
		<router-outlet></router-outlet>
	</div>
</main>


<footer class="p-grid" id="app-footer">
	<div class="p-col-12 text-center">
		<p><span translate>Copyright © {{year}}
			Ford Motor Company. All Rights Reserved. Version</span> {{displayVersion}} </p>
		<p translate>Ford Confidential: This website and the information contained within is Ford Motor Company
			confidential.</p>
	</div>
</footer>
