<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" color="#fff" size="medium" style="position:false !important"
			 type="ball-spin-clockwise-fade-rotating">
</ngx-spinner>
<div *ngIf="isDealer || isDealerGroup">
	<div *ngIf="isAuthorizedUser">
		<form (ngForm)="formWholesale">
			<div class="p-fieldset">
				<div id="tab">
					<div class="grid ui-fluid">

						<div class="col-6">
							<div class="grid">


								<div *ngIf="showDealerDetailDiv" class="col-3">
									<h5 *ngIf="!isDealerGroupDetailDiv"  translate>Dealer</h5>
									<h5 *ngIf="isDealerGroupDetailDiv" translate>Dealer Group</h5>
								</div>
								<div *ngIf="showDealerDetailDiv" class="col-9" id="dealerDiv">
									<h5 translate>: {{dealerId}} {{dealerName}} </h5>
								</div>

								<div class="col-3" >
									<h5 translate>Wholesale Month</h5>
								</div>
								<div class="col-3" id="wholesaleMonthDiv">
									<div class="grid">
										<div class="col-3">:
										</div>
										<div class="col-9">
											<p-dropdown (onChange)="onWholesaleMonthSelect($event.value)" [options]="wholesaleLast12Months"
														[style]="{'width':'180px'}"
														id="select-wholesale-month-history" name="zone"
														placeholder={{selectKeyword}}></p-dropdown>
										</div>
									</div>

								</div>
							</div>

						</div>

					</div>

				</div>
			</div>
		</form>
		<br>
	</div>


	<div *ngIf="isNoRecordFound" class="noRecordFound">
		<p>
			<strong translate>No Volumes allocated for any Vehicle Line</strong>
		</p>
	</div>

	<div *ngIf="isServerError" class="errorAuthId">
		<p>
			<strong>{{errorMsg}}</strong>
		</p>
	</div>


	<div *ngIf="showVehiclesTable" class="app-component">
		<p-table [value]="wholesaleCommitmentResponseList" id="vehiclesTable">
			<ng-template let-columns pTemplate="header" style="background-color: #a8bbe4;">
				<tr>
					<th scope="col" style="background: #27405f;
        color: white;" translate>Vehicle Line
					</th>
					<th scope="col" style="background: #27405f;
        color: white;" translate>Production Month
					</th>
					<th scope="col" *ngIf="isDealerGroupDetailDiv" style="background: #27405f;
        color: white;" translate>Dealer ID
					</th>
					<th scope="col" *ngIf="isDealerGroupDetailDiv" style="background: #27405f;
        color: white;" translate> Dealer Name
					</th>
					<th scope="col" style="background: #27405f;
        color: white;" translate>Allocation
					</th>
					<th scope="col" style="background: #27405f;
        color: white;" translate>Commitment
					</th>
				</tr>
			</ng-template>
			<ng-template let-columns="columns" let-rowData let-rowIndex="rowIndex" pTemplate="body">
				<tr *ngIf="isVehicleLineTypeFirstIndexMatch(rowData, rowIndex)" class="ui-widget-header">
					<td [attr.colspan]="canViewDealerGroup ? '8' : '7'" id="vehicleTypeHeader"
						style="font-size:15px;font-weight: bold;background-color: #1854900f;text-align: center;color:#2c1b5d"
						translate>
						{{vehicleType}} </td>
				</tr>
				<tr *ngIf="isRowVehicleLineFirstIndexMatch(rowData, rowIndex)">
					<td>
						<div class="left-align">{{rowData.vehicleLineName}}</div>
					</td>

					<td>
						<div class="center-align" id="productionMonthAndYear">
							{{(rowData.productionMonthAndYear.substring(0, rowData.productionMonthAndYear.indexOf("-"))
							|translate) + rowData.productionMonthAndYear.substring(rowData.productionMonthAndYear.indexOf("-"), rowData.productionMonthAndYear.length)}}
						</div>
					</td>
					<td *ngIf="isDealerGroupDetailDiv">
						<div class="center-align">{{rowData.dealerId}}</div>
					</td>
					<td *ngIf="isDealerGroupDetailDiv">
						<div class="center-align">{{rowData.dealerName}}</div>
					</td>

					<td>
						<div class="right-align center-right-alignment">{{rowData.productionOffer}}</div>
					</td>

					<td>
						<div
							class="right-align center-right-alignment">{{rowData.allocationVehicleVolumeCommitCount}}</div>
					</td>

				</tr>
			</ng-template>
		</p-table>
	</div>

	<wholesale-tree *ngIf="showVehiclesTreeTable" [isDealerGroupHistory]="true"
					[wholesaleCommitmentResponseList]="wholesaleCommitmentResponseList">
	</wholesale-tree>

</div>
