import {Component, OnInit} from '@angular/core';
import {SpecificationsMapped} from '../models/specification-mapped';
import {NgxSpinnerService} from 'ngx-spinner';
import {WholesaleService} from '../service/wholesale.service';
import {UserService} from '../service/user.service';
import {BulkOrderService} from '../service/bulk-order-service';
import {WholesaleMessageService} from '../service/wholesale-message.service';
import {HttpErrorInterceptor} from '../service/http-error.interceptor';
import {WholesaleConstants} from '../constants/wholesale.constants';
import {formatDate} from '@angular/common';
import {DealerResponse} from '../models/dealer-details';
import {ProductionOfferResponse} from '../models/production-offer-response.model';
import {WholesaleResponse} from '../models/wholesale-response.model';
import {DealerGroupMixRequest} from '../models/dealer-group-mix-request';
import {DealerMixSpecification} from '../models/dealer-mix-specification';
import {DealerGroupMixRequestWrapper} from '../models/dealer-group-mix-request-wrapper';

@Component({
	selector: 'app-dealer-mix-volume',
	templateUrl: './dealer-mix-volume.component.html',
	styleUrls: ['./dealer-mix-volume.component.css']
})
export class DealerMixVolumeComponent implements OnInit {
	public dealerId: string;
	public dealerName: string;
	public vehicleLinesList: any[];
	public specificationsMapped: SpecificationsMapped[] = [];
	public existingList: any[] = [];
	public selectedVehicle: any;
	public errorMsg: string;
	public errorMsgList: string[];
	public first = 0;
	public pageSize = 10;
	public showDealerDetailDiv = false;
	public wholesaleCurrentMonth: any;
	dealerSpecificMix: any;
	public commitmentCount = 0;
	totalEntityMix: number;
	dealerGroupMixRequestList: DealerGroupMixRequest[] = [];

	wholesaleCommitmentResponseList;
	showTableFlag: boolean;
	public groupTotal: number;
	currentYearAndMonth: number;
	public isNoRecordFound = false;
	isServerError: boolean;
	siteCode: string;
	public isApproved = false;
	wholesaleCurrentMonthAndYear: any;
	public wholesaleDealerViewData: string;

	constructor(public spinner: NgxSpinnerService,
				private wholesaleService: WholesaleService,
				private userService: UserService,
				private bulkOrderService: BulkOrderService,
				private wholesaleMessageService: WholesaleMessageService,
				private httpInterceptorService: HttpErrorInterceptor) {
	}

	ngOnInit() {
		this.registerErrorHandler();
		this.showTableFlag = false;
		this.spinner.show();
		this.wholesaleCommitmentResponseList = [];
		this.siteCode = sessionStorage.getItem('loggedInSiteCode');
		this.wholesaleCurrentMonth = this.transformDate(new Date(), WholesaleConstants.dateFormatMMMMYYYY);
		this.wholesaleCurrentMonthAndYear = this.transformDate(new Date().setMonth(new Date().getMonth()), WholesaleConstants.dateFormatYYYYMM);
		this.bulkOrderService.getVehicleLineBoc(true).subscribe(vechileLineData => {
			this.vehicleLinesList = JSON.parse(JSON.stringify(vechileLineData)).vehicleLineResponse;
		});
		this.currentYearAndMonth = Number(this.transformDate(new Date().setMonth(new Date().getMonth()), 'yyyyMM'));
		this.isServerError = false;
		this.getWholesaleDealerDetail(this.siteCode);
	}

	public transformDate(date: string | number | Date, pattern: string) {
		return formatDate(date, pattern, 'en-US');
	}

	getSpecificationsByVehicleLine() {

		this.isServerError = false;
		if (this.selectedVehicle) {
			this.spinner.show();
			this.errorMsgList = [];
			this.errorMsg = '';
			this.specificationsMapped = [];
			if (this.wholesaleCommitmentResponseList.length > 0) {
				this.wholesaleCommitmentResponseList.forEach(commitment => {
					this.commitmentCount = this.commitmentCount + commitment.allocationVehicleVolumeCommitCount;
					if (commitment.isApproved !== null) {
						this.isApproved = commitment.isApproved;
					}
				});
			}
			if (this.commitmentCount === null || this.commitmentCount === 0) {
				this.spinner.hide();
				this.isServerError = true;
				this.errorMsg = WholesaleConstants.noCommitmentForGivenVehicleLine;
			} else if (this.isApproved === false) {
				this.spinner.hide();
				this.isServerError = true;
				this.errorMsg = WholesaleConstants.CommitmentNotApproved;
			} else {
				this.bulkOrderService.getSpecificationsAndMixMapped(
					sessionStorage.getItem('loggedInUserMarketCode'),
					this.selectedVehicle.namePlateId, this.currentYearAndMonth,
					this.selectedVehicle.productionMonth, sessionStorage.getItem('loggedInSiteCode'))
					.subscribe(data => {
						if (data && data.specifications && data.specifications.length === 0) {
							this.spinner.hide();
							this.isServerError = true;
							this.errorMsg = WholesaleConstants.noSpecificationsMappedForGivenVehicleLine;
							this.existingList = data.specifications;
							this.specificationsMapped = data.specifications;
							this.groupTotal = data.allocationCommitted;
							return;
						}
						this.existingList = data.specifications;
						this.specificationsMapped = data.specifications;
						this.groupTotal = data.allocationCommitted;
						this.spinner.hide();
						this.showTableFlag = true;
						this.refreshTotalEntityMixCalculated();
					});
			}
		}
	}

	refreshTotalEntityMixCalculated() {
		let totalEntityMix = 0;
		if (this.specificationsMapped && this.specificationsMapped.length > 0) {
			this.specificationsMapped.forEach(specification => {
				if (specification.volume != undefined) {
					totalEntityMix += specification.volume;
				}
			});
		}
		this.totalEntityMix = totalEntityMix;
	}

	submitAllocationVolume() {
		this.dealerGroupMixRequestList = [];
		this.isServerError = false;
		if (this.selectedVehicle === undefined || this.selectedVehicle === null) {
			this.wholesaleMessageService.showMesssge(WholesaleConstants.selectVehicleLineFirst, WholesaleConstants.error);
		} else {
			const dealerMixSpecificationList: DealerMixSpecification[] = [];
			const dealerGroupMixRequest = new DealerGroupMixRequest();
			dealerGroupMixRequest.dealerId = this.dealerId;
			//Need to give commitment number as volume instead of 0
			dealerGroupMixRequest.volume = 0;
			for (const element of this.specificationsMapped) {
				if (element.volume > 0) {
					const dealerMixSpecification = new DealerMixSpecification();
					dealerMixSpecification.specification = element.specificationKey;
					dealerMixSpecification.volume = element.volume;
					dealerMixSpecificationList.push(dealerMixSpecification);
				}
			}
			if (dealerMixSpecificationList.length === 0) {
				this.wholesaleMessageService.showMesssge(WholesaleConstants.noDealerMixAvailable, WholesaleConstants.error);
			}
			else {
				if (this.totalEntityMix !== this.commitmentCount) {
					this.wholesaleMessageService.showMesssge(WholesaleConstants.entityMixTotalErrorMessage, WholesaleConstants.error);
				} else {
					this.spinner.show();
					dealerGroupMixRequest.specifications = dealerMixSpecificationList;
					this.dealerGroupMixRequestList.push(dealerGroupMixRequest);
					const dealerGroupMixWrapper = new DealerGroupMixRequestWrapper();
					dealerGroupMixWrapper.dealerGroupMixRequestList = this.dealerGroupMixRequestList;
					dealerGroupMixWrapper.userId = sessionStorage.getItem('cdsId');
					dealerGroupMixWrapper.marketKey = sessionStorage.getItem('loggedInUserMarketCode');
					dealerGroupMixWrapper.vehicleLineCode = this.selectedVehicle.namePlateId;
					dealerGroupMixWrapper.wholesaleMonth = this.currentYearAndMonth;
					dealerGroupMixWrapper.productionMonth = this.selectedVehicle.productionMonth;
					this.errorMsgList = [];
					this.errorMsg = '';
					this.bulkOrderService.saveDealerMixByMarketAndVehicleLine(dealerGroupMixWrapper)
						.subscribe(data => {
							this.wholesaleMessageService.showMesssge(WholesaleConstants.dealermixsuccessfullyUpdated, WholesaleConstants.success);
							this.spinner.hide();
						});
				}
			}
		}
	}

	private registerErrorHandler() {
		this.httpInterceptorService.errorStream$().subscribe(errorObj => {
			const url: string = errorObj.url;
			if (!url.includes('WholesaleCommitmentService')) {
				const response = this.isJson(errorObj.error) ? JSON.parse(errorObj.error) : errorObj.error;
				this.isServerError = true;
				let errMessage;
				if (response instanceof ArrayBuffer) {
					errMessage = response;
				} else if (this.isJson(response.error.messages[0])) {
					errMessage = JSON.parse(response.error.messages[0]);
				} else {
					errMessage = response.error.messages[0];
				}

				if (errMessage.error) {
					this.errorMsg = errMessage.message;
					this.errorMsgList = this.errorMsg.split(',');
				} else if (!(errMessage instanceof Object)) {
					this.errorMsg = errMessage;
					this.errorMsgList = this.errorMsg.split(',');
				} else {
					this.errorMsg = errorObj.statusText;
					this.errorMsgList = this.errorMsg.split(',');
				}
			} else {
				this.isServerError = true;
				if (errorObj.error.error) {
					this.errorMsg = errorObj.error.message;
					this.errorMsgList = this.errorMsg.split(',');
				} else if (!(errorObj.error instanceof Object)) {
					this.errorMsg = errorObj.error;
					this.errorMsgList = this.errorMsg.split(',');
				} else {
					this.errorMsg = errorObj.statusText;
					this.errorMsgList = this.errorMsg.split(',');
				}
			}
			this.spinner.hide();
		});
	}

	public getWholesaleDealerDetail(dealerId: string) {
		this.wholesaleService.getDealerDetails(null, null, null, dealerId).subscribe((
			dealerResponse: DealerResponse[]) => {
			this.wholesaleService.getProductionOffersFromDealerRequest(dealerResponse, this.wholesaleCurrentMonthAndYear)
				.subscribe((response: ProductionOfferResponse[]) => {
					this.wholesaleService.getWholesaleDetail(response, this.wholesaleCurrentMonthAndYear).subscribe((response: WholesaleResponse[]) => {
						if (response.length > 0) {
							this.wholesaleCommitmentResponseList = [];
							response.forEach(wholesaleDetail => {
								this.wholesaleCommitmentResponseList.push(wholesaleDetail);
							});
							this.wholesaleDealerViewData = JSON.stringify(response);
							this.dealerId = dealerResponse[0].dealerId;
							this.dealerName = dealerResponse[0].dealerName;
						} else {
							this.dealerId = dealerId;
							this.dealerName = dealerResponse[0].dealerName;

							this.isNoRecordFound = true;
						}
						this.spinner.hide();
						this.showDealerDetailDiv = true;
					});
				}, error => {
					this.spinner.hide();
				});
		});
	}

	private isJson(req): boolean {
		try {
			JSON.parse(req);
			return true;
		} catch (err) {
			return false;
		}
	}
}
